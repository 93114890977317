// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
const endpoint = 'agenda'
export const fetchEvents = createAsyncThunk('agenda/fetchEvents', async params => {
  const response = await axios.get(endpoint, { params })
  const responseTarefas = await axios.get('agenda_tarefa', { params })
  return {
    contratos: response.data.data,
    tarefas: responseTarefas.data.data,
    all: response.data.data.concat(responseTarefas.data.data)
  }
})

export const fetchTipos = createAsyncThunk('agenda/fetchTipos', async calendars => {
  const response = await axios.get('contrato_tipo')
  return response.data.data
})

export const fetchUnidades = createAsyncThunk('agenda/fetchUnidades', async calendars => {
  const response = await axios.get('business_units', {params: {forSelect: true}})
  return response.data.data
})

export const addEvent = createAsyncThunk('agenda/addEvent', async (event, { dispatch, getState }) => {
  const response = await axios.post('orcamentoAgenda', event)
  return response
})

export const updateEvent = createAsyncThunk('appCalendar/updateEvent', async (event, { dispatch, getState }) => {
  await axios.post('/apps/calendar/update-event', { event })
  await dispatch(fetchEvents(getState().calendar.selectedCalendars))
  return event
})


export const updateAllFilters = createAsyncThunk('appCalendar/updateAllFilters', async (value, { dispatch, getState }) => {
  if (value === true) {
    await dispatch(fetchEvents())
  } else {
    await dispatch(fetchEvents(getState().calendar.selectedCalendars))
  }
  return value
})

export const removeEvent = createAsyncThunk('appCalendar/removeEvent', async id => {
  await axios.delete('/apps/calendar/remove-event', { id })
  return id
})



export const AgendaSlice = createSlice({
  name: 'agenda',
  initialState: {
    events: [],
    tipos: [],
    contratos: [],
    tarefas: [],
    unidades: [],
    selectedEvent: {},
    selectedCalendars: [],
    message: ''
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.events = action.payload.all
        state.tarefas = action.payload.tarefas
        state.contratos = action.payload.contratos
      })
      .addCase(fetchTipos.fulfilled, (state, action) => {
        state.tipos = action.payload
      })
      .addCase(fetchUnidades.fulfilled, (state, action) => {
        state.unidades = action.payload
      })
      .addCase(addEvent.fulfilled, (state, action) => {
        state.status =  'SAVE_SUCCESS'
        state.message = action.payload.message
      })
      .addCase(updateAllFilters.fulfilled, (state, action) => {
        const value = action.payload
        let selected = []
        if (value === true) {
          selected = state.tipos
        } else {
          selected = []
        }
        state.selectedCalendars = selected
      })
  }
})

export const { selectEvent } = AgendaSlice.actions

export default AgendaSlice.reducer
