import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const endpoint = 'roles'

export const getAll = createAsyncThunk(`${endpoint}/all`, async params => {
  const response = await axios.get(endpoint, {params})
  return { data: response.data }
})


export const get = createAsyncThunk(`${endpoint}/get`, async id => {
  const response = await axios.get(`${endpoint}/${id}`, { id })
  return response.data
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item, { dispatch, rejectWithValue }) => {
  try {
      await axios.post(endpoint, item)
      await dispatch(getAll())
  } catch (e) {
      if (!e.response) {
          throw e
      }

      return rejectWithValue(e.response.data)
  }

  return item
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item, {dispatch}) => {
  await axios.put(`${endpoint}/${item.id}`, item)
  await dispatch(get(item.id))
  return item
})

export const remove = createAsyncThunk(`${endpoint}/remove`, async (item, {dispatch}) => {
  await axios.delete(`${endpoint}/${item.id}`)
  await dispatch(getAll())
  return item
})


export const updateLoggedOrganization = createAsyncThunk(`organization/update`, async (data, {dispatch}) => {
    const response = await axios.put(`organization/update`, data)
    return response.data
})

export const rolesSlice = createSlice({
  name: endpoint,
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(getAll.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.status = 'SUCCESS'
        })
        .addCase(getAll.pending, (state) => {
            state.loading = true
            state.status = 'PENDING'
        })
        .addCase(getAll.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload || 'Não foi possível carregar os dados'
            state.status = 'ERROR'
        })
        .addCase(get.fulfilled, (state, action) => {
          state.status = 'SUCCESS'
          state.selected = action.payload
        })
        .addCase(add.fulfilled, (state, action) => {
            state.selected = action.payload
            state.status = 'SAVE_SUCCESS'
        })
        .addCase(add.rejected, (state, action) => {
            state.status = 'SAVE_ERROR'
            state.error = action.payload || 'Não foi possível salvar'
            state.loading = false
        })
        .addCase(update.fulfilled, (state) => {
            state.status = 'SAVE_SUCCESS'
        })
        .addCase(update.rejected, (state, action) => {
            state.error = action.error
            state.status = 'SAVE_ERROR'
        })
        .addCase(remove.pending, (state, action) => {
            state.status = 'ERROR'

        })

  }
})

export default rolesSlice.reducer
