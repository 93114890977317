import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = 'proposals'

export const get = createAsyncThunk(`${endpoint}/get`, async params => {
    const response = await axios.get(`${endpoint}`, { params })
    return response.data
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item, { dispatch, rejectWithValue }) => {

    try {
      const response = await axios.post(endpoint, item)
      return response.data
    } catch (e) {
      if (!e.response) {
        throw e
      }
      return rejectWithValue(e.response.data)
    }
  })

  export const getCloseBusinessProposal = createAsyncThunk(`${endpoint}/close`, async (item, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${endpoint}/close`, item)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  });

export const proposalSlice = createSlice({
    name: endpoint,
    initialState: {
      data: [],
      status: 'READY',
      total: 0,
      params: {},
      closeBusiness: {},
      selected: {},
      error: ''
    },
    reducers: {},
    extraReducers: builder => {
        builder
        .addCase(get.pending, (state, action) => {
            state.status = 'PENDING'
            state.data = action.payload
          })
          .addCase(get.rejected, (state, action) => {
            state.status = 'ERROR'
            state.error = action.payload
          })
          .addCase(get.fulfilled, (state, action) => {
            state.status = 'SUCCESS'
            state.data = action.payload
          })
          .addCase(add.fulfilled, (state, action) => {
            state.status = 'SAVE_SUCCESS'
            state.message = 'Salvo com Sucesso!'
          })
          .addCase(add.rejected, (state, action) => {
            state.status = 'SAVE_ERROR'
            state.message = action.payload.message || 'Não foi possível salvar'
          })
          .addCase(getCloseBusinessProposal.pending, (state, action) => {
            state.status = 'PENDING'
          })
          .addCase(getCloseBusinessProposal.rejected, (state, action) => {
            state.status = 'ERROR'
            state.error = action?.payload?.message
          })
          .addCase(getCloseBusinessProposal.fulfilled, (state, action) => {
            state.status = 'SUCCESS'
            state.closeBusiness = action.payload
          })
    }
})

export default proposalSlice.reducer 