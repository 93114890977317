export const url = process.env.REACT_APP_API_URL
export const authUrl = process.env.REACT_APP_API_AUTH_URL
export const loggedIn = process.env.REACT_APP_API_URL + '/me'

export function getCookie(name) {
    const cookie = {}
    document.cookie.split(';').forEach(function(el) {
        const [k, v] = el.split('=')
        cookie[k.trim()] = v
    })
    return decodeURI(cookie[name])
}
