import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { packagesSlice } from "../../packages/store";
const endpoint = 'templates'

export const getAll = createAsyncThunk(`${endpoint}/all`, async params => {
    const response = await axios.get(`${endpoint}`, { params })
    return {
        data: response.data.data,
        total: response.data.length,
        params
    }
})

export const get = createAsyncThunk(`${endpoint}/get`, async id => {
    const response = await axios.get(`${endpoint}/${id}`, { id })
    return response.data.data
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item, { dispatch, rejectWithValue }) => {
    const response = await axios.post(`${endpoint}`, item)
    return {
        message: response.data.message,
    }
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item, { dispatch }) => {
    const response = await axios.put(`${endpoint}/${item.id}`, item)
    return {
        message: response.data.message,
    }
})

export const remove = createAsyncThunk(`${endpoint}/remove`, async (id, { dispatch }) => {
    const response = await axios.delete(`${endpoint}/${id}`)
    return {
        message: response.data.message,
    }
})

export const removeMultiples = createAsyncThunk(`${endpoint}/removeMultiples`, async (ids, { dispatch }) => {
    await axios.delete(`${endpoint}/delete-multiples`, { data: { ids } })
})

export const templatesSlice = createSlice({
    name: endpoint,
    initialState: {
        data: [],
        message: '',
        status: 'READY',
        total: 0,
        params: {},
        error: '',
        pages: 1,
        selected: {},
        tipos: [
            { label: 'Contrato', value: 'contrato' },
            { label: 'Convidado', value: 'convidado' },
            { label: 'Email', value: 'email' },
            { label: 'Lembrete de fatura', value: 'lembrete_fatura' },
            { label: 'Lembrete de fatura via whatsapp', value: 'whatsapp_fatura' },
            { label: 'Recibo de fatura', value: 'recibo_fatura' },
        ]
    },
    reducers: {
        clearSelected: (state, payload) => {
            state.selected = {}
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAll.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.total
                state.status = 'SUCCESS'
                state.loading = false
            })
            .addCase(getAll.pending, (state) => {
                state.loading = true
                state.data = []
                state.status = 'PENDING'
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.data = []
                state.error = action.payload || 'Não foi possível carregar os dados'
                state.status = 'ERROR'
            })
            .addCase(get.pending, (state, action) => {
                state.status = 'PENDING'
                state.selected = action.payload
            })
            .addCase(get.rejected, (state, action) => {
                state.status = 'ERROR'
                state.error = action.payload
            })
            .addCase(get.fulfilled, (state, action) => {
                state.status = 'SUCCESS'
                state.selected = action.payload
            })
            .addCase(add.fulfilled, (state, action) => {
                state.status = 'SAVE_SUCCESS'
                state.message = action.payload.message
            })
            .addCase(add.rejected, (state, action) => {
                state.status = 'SAVE_ERROR'
                state.message = action.payload.message || 'Não foi possível salvar'
                state.loading = false
            })
            .addCase(update.fulfilled, (state, action) => {
                state.status = 'SAVE_SUCCESS'
                state.message = action.payload.message
            })
            .addCase(update.rejected, (state, action) => {
                state.status = 'SAVE_ERROR'
                state.message = action.payload.message || 'Não foi possível salvar'
                state.loading = false
            })
            .addCase(remove.rejected, (state, action) => {
                state.status = 'REMOVE_ERROR'
            })
            .addCase(remove.fulfilled, (state, action) => {
                state.status = 'REMOVE_SUCCESS'
            })
            .addCase(remove.pending, (state, action) => {
                state.status = 'REMOVE_PENDING'
            })
            .addCase(removeMultiples.rejected, (state, action) => {
                state.status = 'REMOVE_ERROR'
            })
            .addCase(removeMultiples.fulfilled, (state, action) => {
                state.status = 'REMOVE_SUCCESS'
            })
            .addCase(removeMultiples.pending, (state, action) => {
                state.status = 'REMOVE_PENDING'
            })
    }
})

export default templatesSlice.reducer
export const { clearSelected } = templatesSlice.actions