import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const endpoint = 'customer_area'

export const getAll = createAsyncThunk(`${endpoint}/events/all`, async params => {
  const response = await axios.get(`${endpoint}/events`, {params})
  return {
    data: response.data.data,
    total: response.data.meta.total,
      last_page: response.data.meta.last_page,
      current_page: response.data.meta.current_page,
    params
  }
})

export const get = createAsyncThunk(`${endpoint}/get`, async id => {
  const response = await axios.get(`${endpoint}/event/${id}`, { id })
  return response.data.data
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item, {dispatch}) => {
  await axios.put(`${endpoint}/event/${item.id}`, item)
  return item.data
})

export const addConvidado = createAsyncThunk(`${endpoint}/addConvidado`, async (item, {dispatch}) => {
  const response = await axios.post(`${endpoint}/convidados`, item)
    return response.data
})
export const updateConvidado = createAsyncThunk(`${endpoint}/updateConvidado`, async (item, {dispatch}) => {
  const response = await axios.put(`${endpoint}/convidados/${item.id}`, item)
    return response.data
})
export const removeConvidado = createAsyncThunk(`${endpoint}/removeConvidado`, async (id, {dispatch}) => {
  const response = await axios.delete(`${endpoint}/convidados/${id}`)
    return response.data
})


export const customer_area = createSlice({
  name: endpoint,
  initialState: {
    data: [],
    convidados: [],
      adultos: [],
    message: '',
    item: {},
    status: 'READY',
    total: 0,
    last_page: 1,
      current_page: 1,
    params: {},
    error: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(getAll.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.total
          state.last_page = action.payload.last_page
          state.status = 'SUCCESS'
        })
        .addCase(getAll.pending, (state) => {
          state.loading = true
          state.data = []
          state.status = 'PENDING'
        })
        .addCase(getAll.rejected, (state, action) => {
          state.loading = false
          state.data = []
          state.error = action.payload || 'Não foi possível carregar os dados'
          state.status = 'ERROR'
        })
        .addCase(get.pending, (state, action) => {
          state.status = 'PENDING'
          state.selected = action.payload
        })
        .addCase(get.rejected, (state, action) => {
          state.status = 'ERROR'
          state.error = action.payload
        })
        .addCase(get.fulfilled, (state, action) => {
          state.status = 'SUCCESS'
          state.item = action.payload
          state.convidados = action.payload.convidados
          state.adultos = action.payload.adultos
        })
        .addCase(update.fulfilled, (state, action) => {
            state.status = 'SAVE_SUCCESS'
            state.message = action.payload.message
        })
        .addCase(updateConvidado.fulfilled, (state, action) => {
            state.status = 'SAVE_SUCCESS'
            state.message = action.payload.message
        })
        .addCase(addConvidado.fulfilled, (state, action) => {
            state.status = 'SAVE_SUCCESS'
            state.message = action.payload.message
        })
        .addCase(removeConvidado.fulfilled, (state, action) => {
            state.status = 'REMOVE_SUCCESS'
            state.message = action.payload.message
        })
        .addCase(update.rejected, (state, action) => {
            state.status = 'SAVE_ERROR'
            state.message = action.payload.message || 'Não foi possível salvar'
            state.loading = false
        })

  }
})

export default customer_area.reducer
