import React, { useState } from "react";

export const ValueContext = React.createContext()

export const ValueProvider = (props) => {
    const [fullValue, setFullValue] = useState('')

    return (
        <ValueContext.Provider value={{fullValue, setFullValue}}>
            {props.children}
        </ValueContext.Provider>
    )
}