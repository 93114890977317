import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import {url} from '../../../configs/url'
import { authUrl } from '../../../configs/url'

export default class JwtService {
    // ** jwtConfig <= Will be used by this service
    jwtConfig = {...jwtDefaultConfig}

    constructor(jwtOverrideConfig) {
        this.jwtConfig = {...this.jwtConfig, ...jwtOverrideConfig}

        // ** Request Interceptor
        axios.defaults.withCredentials = true

        axios.interceptors.request.use(
            config => {
                config.baseURL = url
                return config
            },
            error => Promise.reject(error)
        )

        // ** Add request/response interceptor
        axios.interceptors.response.use(
            response => response,
            error => {
                // ** const { config, response: { status } } = error
                const {config, response} = error
                const originalRequest = config
                if (response.status === 401 && originalRequest.url !== "/login") {
                    localStorage.clear()
                    window.location.href = "/login";
                }
                return Promise.reject(error)
            }
        )
    }

    login(...args) {
        return axios.get('sanctum/csrf-cookie').then((res) => {

            return axios.post(this.jwtConfig.loginEndpoint, ...args)
        })
    }

    register(...args) {
        return axios.post(`${authUrl}/api/register`, ...args)
    }

    forgot(...args) {
        return axios.post(this.jwtConfig.forgotEndpoint, ...args)
    }

    resetPassword(...args) {
        return axios.post(this.jwtConfig.resetPasswordEndpoint, ...args)
    }

}
