import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
const endpoint = 'packages'

export const getAll = createAsyncThunk(`${endpoint}/all`, async params => {
  const response = await axios.get(`${endpoint}`, {params})
  return {
    data: response.data.data,
    total: response.data.data.length,
    params
  }
})

export const get = createAsyncThunk(`${endpoint}/get`, async id => {
  const response = await axios.get(`${endpoint}/${id}`, { id })
  return response.data.data
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item, { dispatch, rejectWithValue }) => {
    const response = await axios.post(`${endpoint}`,  item)
    return response.data
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item, {dispatch}) => {
    const response = await axios.put(`${endpoint}/${item.id}`, item)
    return response.data
})

export const remove = createAsyncThunk(`${endpoint}/remove`, async (id, {dispatch}) => {
  await axios.delete(`${endpoint}/${id}`)
  await dispatch(getAll())
  return id
})

export const removePrices = createAsyncThunk('/cardapio_prices', async (priceId, {dispatch}) => {
  await axios.delete(`/cardapio_prices/${priceId}`)
  return priceId
})

export const removeMultiples = createAsyncThunk('cardapio/removeMultiples', async (ids, { dispatch }) => {
  await axios.delete('cardapio/delete-multiples', {data: { ids }} )
})

export const packagesSlice = createSlice({
  name: 'packages',
  initialState: {
    data: [],
    status: 'READY',
    total: 0,
    params: {},
    selected: {},
    error: ''
  },
  reducers: {
      clearSelected: (state, payload) => {
          state.selected = {}
      }
  },
  extraReducers: builder => {
    builder
        .addCase(getAll.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.total
          state.status = 'SUCCESS'
        })
        .addCase(getAll.pending, (state) => {
          state.loading = true
          state.data = []
          state.status = 'PENDING'
        })
        .addCase(getAll.rejected, (state, action) => {
          state.loading = false
          state.data = []
          state.error = action.payload || 'Não foi possível carregar os dados'
          state.status = 'ERROR'
        })
        .addCase(get.pending, (state, action) => {
          state.status = 'PENDING'
          state.selected = action.payload
        })
        .addCase(get.rejected, (state, action) => {
          state.status = 'ERROR'
          state.error = action.payload
        })
        .addCase(get.fulfilled, (state, action) => {
          state.status = 'SUCCESS'
          state.selected = action.payload
        })
        .addCase(add.fulfilled, (state, action) => {
          state.status = 'SAVE_SUCCESS'
          state.message = action.payload.message
        })
        .addCase(add.rejected, (state, action) => {
          state.status = 'SAVE_ERROR'
          state.message = action.error.message
          state.loading = false
        })
        .addCase(update.fulfilled, (state, action) => {
          state.status = 'SAVE_SUCCESS'
          state.message = action.payload.message
        })
        .addCase(update.rejected, (state, action) => {
          state.status = 'SAVE_ERROR'
          state.message = action.payload?.message || 'Não foi possível salvar'
          state.loading = false
        })
        .addCase(remove.rejected, (state, action) => {
          state.selected = action.error
          state.status = 'REMOVE_ERROR'
        })
        .addCase(remove.fulfilled, (state, action) => {
          state.status = 'REMOVE_SUCCESS'
        })
        .addCase(remove.pending, (state, action) => {
          state.status = 'REMOVE_PENDING'
        })
        .addCase(removePrices.rejected, (state, action) => {
          state.selected = action.error
          state.message = 'Não é possível excluir pois já está vinculado a um contrato!'
          state.status = 'REMOVE_PRICE_ERROR'
        })
        .addCase(removePrices.fulfilled, (state, action) => {
          state.message = 'Preço removido com sucesso!'
          state.status = 'REMOVE_PRICE_SUCCESS'
        })
        .addCase(removePrices.pending, (state, action) => {
          state.status = 'REMOVE_PENDING'
        })
        .addCase(removeMultiples.rejected, (state, action) => {
          state.status = 'REMOVE_ERROR'
        })
        .addCase(removeMultiples.fulfilled, (state, action) => {
          state.status = 'REMOVE_SUCCESS'
        })
        .addCase(removeMultiples.pending, (state, action) => {
          state.status = 'REMOVE_PENDING'
        })

  }
})
export const {clearSelected } = packagesSlice.actions
export default packagesSlice.reducer
