import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
const endpoint = 'users'

export const getAll = createAsyncThunk(`${endpoint}/all`, async params => {

  const response = await axios.get(`${endpoint}`, { params })

  return {
    data: response.data.data,
    total: response.data.meta.total,
    params
  }
})

export const get = createAsyncThunk(`${endpoint}/get`, async id => {
  const response = await axios.get(`${endpoint}/${id}`, { id })
  return response.data
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item, { dispatch, rejectWithValue }) => {
  try {
    await axios.post(endpoint, item)
    await dispatch(getAll({ id: item.fk_organizacao }))
  } catch (e) {
    if (!e.response) {
      throw e
    }

    return rejectWithValue(e.response.data)
  }

  return item
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item, { dispatch }) => {
  await axios.put(`${endpoint}/${item.id}`, item)
  return item
})
export const suspend = createAsyncThunk(`${endpoint}/suspend`, async (item, { dispatch }) => {
  await axios.put(`${endpoint}/${item.id}`, {
    status: item.status
  })
  return item
})

export const remove = createAsyncThunk(`${endpoint}/remove`, async (item, { dispatch }) => {
  await axios.delete(`${endpoint}/${item.id}`)
  await dispatch(getAll())
  return item
})


export const usersSlice = createSlice({
  name: endpoint,
  initialState: {
    data: [],
    status: 'READY',
    total: 0,
    params: {},
    selected: null,
    error: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.status = 'SUCCESS'
      })
      .addCase(getAll.pending, (state) => {
        state.loading = true
        state.data = []
        state.status = 'PENDING'
      })
      .addCase(getAll.rejected, (state, action) => {
        state.loading = false
        state.data = []
        state.error = action.payload || 'Não foi possível carregar os dados'
        state.status = 'ERROR'
      })
      .addCase(get.pending, (state, action) => {
        state.status = 'PENDING'
        state.selected = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = action.payload
      })
      .addCase(get.fulfilled, (state, action) => {
        state.status = 'SUCCESS'
        state.selected = action.payload
      })
      .addCase(add.fulfilled, (state, action) => {
        state.status = 'SAVE_SUCCESS'
        state.selected = action.payload
      })
      .addCase(add.rejected, (state, action) => {
        state.status = 'SAVE_ERROR'
        state.error = action.payload || 'Não foi possível salvar'
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = 'UPDATED_SUCCESS'
        state.message = 'Atualizado com Sucesso!'
        state.selected = action.payload
      })
      .addCase(update.pending, (state) => {
        state.status = 'UPDATED_PENDING'
      })
      .addCase(update.rejected, (state, action) => {
        state.status = 'UPDATED_ERROR'
        state.message = action.payload?.message || 'Não foi possível salvar'
      })
      .addCase(suspend.rejected, (state, action) => {
        state.status = 'SUSPEND_ERROR'
        state.loading =  true
      })
      .addCase(suspend.fulfilled, (state, action) => {
        state.status = 'SUSPEND_SUCCESS'
        state.loading =  false
      })
  }
})

export default usersSlice.reducer
