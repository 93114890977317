import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const endpoint = 'bank_accounts'

export const getAll = createAsyncThunk(`${endpoint}/all`, async params => {
    const response = await axios.get(`${endpoint}`, {params})
  return { data: response.data.data, total: response.data?.total, params }
})


export const get = createAsyncThunk(`${endpoint}/get`, async id => {
  const response = await axios.get(`${endpoint}/${id}`, { id })
  return response.data
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item, { dispatch, rejectWithValue }) => {
  try {
      await axios.post(endpoint, item)
      await dispatch(getAll({id: item.fk_organizacao}))
  } catch (e) {
      if (!e.response) {
          throw e
      }

      return rejectWithValue(e.response.data)
  }

  return item
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item, {dispatch}) => {
  await axios.put(`${endpoint}/${item.id}`, item)
  return item
})

export const remove = createAsyncThunk(`${endpoint}/remove`, async (item, {dispatch}) => {
  await axios.delete(`${endpoint}/${item.id}`)
  await dispatch(getAll({id: item.fk_organizacao}))
  return item
})


export const bankAccountsSlice = createSlice({
  name: endpoint,
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(getAll.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.total
            state.status = 'SUCCESS'
        })
        .addCase(getAll.pending, (state) => {
            state.loading = true
            state.status = 'PENDING'
        })
        .addCase(getAll.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.payload || 'Não foi possível carregar os dados'
            state.status = 'ERROR'
        })
        .addCase(get.pending, (state, action) => {
          state.status = 'PENDING'
          state.selected = action.payload
        })
        .addCase(get.rejected, (state, action) => {
          state.status = 'ERROR'
          state.error = action.payload
        })
        .addCase(get.fulfilled, (state, action) => {
          state.status = 'SUCCESS'
          state.selected = action.payload
        })
        .addCase(add.fulfilled, (state, action) => {
            state.selected = action.payload
            state.status = 'SAVE_SUCCESS'
        })
        .addCase(add.rejected, (state, action) => {
            state.status = 'SAVE_ERROR'
            state.error = action.payload || 'Não foi possível salvar'
            state.loading = false
        })
        .addCase(update.fulfilled, (state, action) => {
            state.status = 'SAVE_SUCCESS'
        })
        .addCase(update.rejected, (state, action) => {
          state.selected = action.error
            state.status = 'SAVE_ERROR'
        })
        .addCase(remove.rejected, (state, action) => {
          state.selected = action.error
            state.status = 'DELETE_ERROR'
        })
        .addCase(remove.fulfilled, (state, action) => {
          state.status = 'DELETE_SUCCESS'
        })
  }
})

export default bankAccountsSlice.reducer
