import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
const endpoint = 'orcamento'

export const getAll = createAsyncThunk(`${endpoint}/all`, async params => {
  const response = await axios.get(`${endpoint}`, { params })

  return {
    data: response.data.data,
    total: response.data.meta.total,
    last_page: response.data.meta.last_page,
    funil: response.data.funil,
    params
  }
})

export const get = createAsyncThunk(`${endpoint}/get`, async params => {
  const response = await axios.get(`${endpoint}/${params.id}`, { params })
  return response.data.data
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item, { dispatch, rejectWithValue }) => {


  try {
    const response = await axios.post(endpoint, item)

    return response.data.data
  } catch (e) {
    if (!e.response) {
      throw e
    }

    return rejectWithValue(e.response.data)
  }

  // return item
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item, { dispatch }) => {
  const response = await axios.put(`${endpoint}/${item.id}`, item)
  return response.data.data
})

export const historico = createAsyncThunk(`${endpoint}/historic`, async (item, { dispatch }) => {
  const response = await axios.get(`${endpoint}/historic/${item.id}`, item)
  return response.data.data
})

export const removeVendedor = createAsyncThunk(`${endpoint}/removeVendedor`, async (item, { dispatch }) => {
  const response = await axios.put(`${endpoint}/${item.id}`, {
    nome: item.nome,
    email: item.email,
    celular: item.celular,
    vendedor_id: item.vendedor_id,
    remover_vendedor: true
  })
  return response.data.data
})

export const remove = createAsyncThunk(`${endpoint}/remove`, async (id, { dispatch }) => {
  await axios.delete(`${endpoint}/${id}`)
  await dispatch(getAll())
  return id
})

export const removeMultiples = createAsyncThunk(`${endpoint}/removeMultiples`, async (ids, { dispatch }) => {
  await axios.delete(`${endpoint}/delete-multiples`, {data: { ids }} )
})

export const orcamentoSlice = createSlice({
  name: 'orcamento',
  initialState: {
    tasks: [],
    boards: [],
    selectedTask: null,
    data: [],
    funil: [],
    historicoFunil: [],
    tarefas: [],
    message: '',
    status: 'READY',
    total: 0,
    params: {},
    error: '',
    editOpen: false,
    deleteOpen: false,
    loading: false,
    selected: {}
  },
  reducers: {
    handleSelectTask: (state, action) => {
      state.selectedTask = action.payload
    },
    clearSelected: (state, payload) => {
      state.selected = {}
  },
    toggleModal: (state, action) => {
      state.editOpen = !state.editOpen
    },
    toggleDelete: (state, action) => {
      state.deleteOpen = !state.deleteOpen
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.last_page = action.payload.last_page
        state.funil = action.payload.funil
        state.status = 'SUCCESS'
        state.loading = false
      })
      .addCase(getAll.pending, (state) => {
        state.loading = true
        state.data = []
        state.status = 'PENDING'
      })
      .addCase(getAll.rejected, (state, action) => {
        state.loading = false
        state.data = []
        state.error = action.payload || 'Não foi possível carregar os dados'
        state.status = 'ERROR'
      })
      .addCase(get.pending, (state, action) => {
        state.status = 'PENDING'
        state.selected = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = action.payload
      })
      .addCase(get.fulfilled, (state, action) => {
        state.status = 'SUCCESS'
        state.selected = action.payload
        state.tarefas = action.payload.tarefas
      })
      .addCase(add.fulfilled, (state, action) => {
        state.status = 'SAVE_SUCCESS'
        state.message = 'Salvo com Sucesso!'
      })
      .addCase(add.rejected, (state, action) => {
        state.status = 'SAVE_ERROR'
        state.message = action.payload.message || 'Não foi possível salvar'
        state.loading = false
      })
      .addCase(update.fulfilled, (state, action) => { 
        state.status = 'UPDATED_SUCCESS'
        state.message = 'Atualizado com Sucesso!'
        state.selected = action.payload
        state.editOpen = false
        state.loading = false
      })
      .addCase(update.pending, (state) => {
        state.status = 'PENDING'
        state.loading = true
    })
      .addCase(update.rejected, (state, action) => {
        state.status = 'SAVE_ERROR'
        state.message = action.payload?.message || 'Não foi possível salvar'
        state.loading = false
      })
      .addCase(historico.rejected, (state, action) => {
        state.status = 'ERROR_HISTORIC'
        state.loading =  true
      })
      .addCase(historico.fulfilled, (state, action) => {
        state.historicoFunil = action.payload
        state.loading =  false
      })
      .addCase(historico.pending, (state, action) => {
        state.status = 'PENDING_HISTORIC'
        state.loading =  true
      })
      .addCase(remove.rejected, (state, action) => {
        state.status = 'REMOVE_ERROR'
        state.loading =  true
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.status = 'REMOVE_SUCCESS'
        state.loading =  false
      })
      .addCase(remove.pending, (state, action) => {
        state.status = 'REMOVE_PENDING'
        state.loading =  true
      })
      .addCase(removeVendedor.rejected, (state, action) => {
        state.status = 'REMOVE_ERROR'
        state.loading =  true
      })
      .addCase(removeVendedor.fulfilled, (state, action) => {
        state.status = 'REMOVE_SELLER_SUCCESS'
        state.loading =  false
      })
      .addCase(removeMultiples.rejected, (state, action) => {
        state.status = 'REMOVE_ERROR'
      })
      .addCase(removeMultiples.fulfilled, (state, action) => {
        state.status = 'REMOVE_SUCCESS'
      })
      .addCase(removeMultiples.pending, (state, action) => {
        state.status = 'REMOVE_PENDING'
      })
  }
})

export const {clearSelected } = orcamentoSlice.actions
export const { openModal, toggleModal, toggleDelete } = orcamentoSlice.actions
export default orcamentoSlice.reducer 