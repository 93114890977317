import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
const endpoint = 'custom_fields'

export const getAll = createAsyncThunk(`${endpoint}/all`, async params => {

    const response = await axios.get(`${endpoint}`, {params})
     return {
      data: response.data,
      total: response.data.lenght,
      params
  }
})

export const get = createAsyncThunk(`${endpoint}/get`, async id => {
  const response = await axios.get(`${endpoint}/${id}`, { id })
  return response.data
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item, { dispatch, rejectWithValue }) => {


  try {
    const response = await axios.post(endpoint, item)

    return response.data
  } catch (e) {

    if (!e.response) {
      throw e
    }

    return rejectWithValue(e.response.data)
  }

  return item
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item, {dispatch}) => {
  await axios.put(`${endpoint}/${item.id}`, item)
  return item
})


export const remove = createAsyncThunk(`${endpoint}/remove`, async (id, {dispatch}) => {
  await axios.delete(`${endpoint}/${id}`)
  await dispatch(getAll())
})

export const removeMultiples = createAsyncThunk(`${endpoint}/removeMultiples`, async (ids, { dispatch }) => {
  await axios.delete(`${endpoint}/delete-multiples`, {data: { ids }} )
})

export const customFieldsSlice = createSlice({
  name: 'customFields',
  initialState: {
    data: [],
    message: '',
    status: 'READY',
    total: 0,
    params: {},
    error: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(getAll.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.total
          state.status = 'SUCCESS'
        })
        .addCase(getAll.pending, (state) => {
          state.loading = true
          state.data = []
          state.status = 'PENDING'
        })
        .addCase(getAll.rejected, (state, action) => {
          state.loading = false
          state.data = []
          state.error = action.payload || 'Não foi possível carregar os dados'
          state.status = 'ERROR'
        })
        .addCase(get.pending, (state, action) => {
          state.status = 'PENDING'
          state.selected = action.payload
        })
        .addCase(get.rejected, (state, action) => {
          state.status = 'ERROR'
          state.error = action.payload
        })
        .addCase(get.fulfilled, (state, action) => {
          state.status = 'SUCCESS'
          state.selected = action.payload
        })
        .addCase(add.fulfilled, (state, action) => {
          state.status = 'SAVE_SUCCESS'
          state.message = action.payload.message
        })
        .addCase(add.rejected, (state, action) => {
          state.status = 'SAVE_ERROR'
          state.message = action.payload.message || 'Não foi possível salvar'
          state.loading = false
        })
        .addCase(update.fulfilled, (state, action) => {
            state.status = 'SAVE_SUCCESS'
            state.message = action.payload.message
        })
        .addCase(update.rejected, (state, action) => {
            state.status = 'SAVE_ERROR'
            state.message = action.payload.message || 'Não foi possível salvar'
            state.loading = false
        })
        .addCase(remove.rejected, (state, action) => {
          state.status = 'REMOVE_ERROR'
        })
        .addCase(remove.fulfilled, (state, action) => {
          state.status = 'REMOVE_SUCCESS'
        })
        .addCase(remove.pending, (state, action) => {
          state.status = 'REMOVE_PENDING'
        })
        .addCase(removeMultiples.rejected, (state, action) => {
          state.status = 'REMOVE_ERROR'
        })
        .addCase(removeMultiples.fulfilled, (state, action) => {
          state.status = 'REMOVE_SUCCESS'
        })
        .addCase(removeMultiples.pending, (state, action) => {
          state.status = 'REMOVE_PENDING'
        })
  }
})

export default customFieldsSlice.reducer
