// ** Auth Endpoints
export default {
  loginEndpoint: '/login',
  registerEndpoint: '/register',
  logoutEndpoint: '/logout',
  forgotEndpoint: '/forgot_password',
  resetPasswordEndpoint: '/reset_password',
  reportFaturas: '/faturas_report',
  storageTokenKeyName: 'token'
}
